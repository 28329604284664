<template>
  <div>
    <h6 style="margin-bottom: 10px">
      <router-link
        v-if="$route.query.dateRange && $route.query.clinicName"
        :to="{
          name: backRoute,
          query: {
            clinicId: $route.query.clinicId,
            clinicName: $route.query.clinicName,
            year: $route.query.year,
            month: $route.query.month,
            dateRange: $route.query.dateRange,
            activeView: $route.query.activeView,
          },
        }"
        ><span @click="$router.go(-1)" role="link">&#8592; Go back</span></router-link
      >
      <router-link
        v-else-if="$route.query.dateRange && !$route.query.clinicName"
        :to="{
          name: backRoute,
          params: { clinicId: $route.query.clinicId },
          query: {
            year: $route.query.year,
            month: $route.query.month,
            dateRange: $route.query.dateRange,
            activeView: $route.query.activeView,
          },
        }"
        ><span @click="$router.go(-1)" role="link">&#8592; Go back</span></router-link
      >
      <router-link
        v-else-if="!$route.query.dateRange && $route.query.backRoute"
        :to="{ name: backRoute }"
        ><span @click="$router.go(-1)" role="link">&#8592; Go back</span></router-link
      >
      <router-link
        v-else
        :to="{
          name: backRoute,
          params: { patientId: $route.params.patientId },
        }"
        ><span @click="$router.go(-1)" role="link">&#8592; Go back</span></router-link
      >
    </h6>
    <vs-card class="fontStyle">
      <h4 class="mb-3 ml-5">Overview</h4>
      <!-- view script pdf button here -->
      <div
        v-if="
          modalData.appointments.doctorTreatmentOutcome &&
          modalData.appointments.doctorTreatmentOutcome.title &&
          modalData.appointments.doctorTreatmentOutcome.title === 'Approved'
        "
      >
        <div style="position: relative">
          <div style="position: absolute; right: 0">
            <vs-button
              @click="showScriptPdfClicked()"
              v-if="!isDocEMRTreatment"
              color="primary"
              type="border"
              >View Treatment PDF</vs-button
            >
          </div>
        </div>
      </div>
      <p class="ml-5 mb-1" style="clear: both">
        Date:
        {{
          moment(modalData.appointments.appointmentDateTime).format(
            "DD/MM/YYYY"
          )
        }}
      </p>
      <p class="ml-5 mb-1">
        Nurse:
        {{
          modalData.nurse
            ? modalData.nurse.firstName + " " + modalData.nurse.lastName
            : "N/A"
        }}
      </p>
      <p class="ml-5 mb-1">
        Doctor:
        {{
          modalData.doctor
            ? modalData.doctor.firstName + " " + modalData.doctor.lastName
            : "N/A"
        }}
      </p>
      <p class="ml-5 mb-1">
        Patient: {{ modalData.patient.firstName }}
        {{ modalData.patient.lastName }}
      </p>
      <div v-if="isScripted" style="width: 90px">
        <p
          v-if="showScripted"
          style="cursor: pointer"
          @click="
            getParentData(modalData.parentAppointmentData[0].patientCheckinId)
          "
          class="ml-5 mb-2"
        >
          <vx-tooltip text="View Parent Appointment" position="top">
            <vs-chip
              style="margin-bottom: 10px; border-radius: 15px"
              color="#33B76B"
              >Scripted</vs-chip
            >
          </vx-tooltip>
        </p>

        <p
          v-else
          style="cursor: pointer"
          @click="getChildData()"
          class="ml-5 mb-2"
        >
          <vx-tooltip text="Back To Main Appointment" position="top">
            <vs-chip
              style="margin-bottom: 10px; border-radius: 15px"
              color="#33B76B"
              >Back</vs-chip
            >
          </vx-tooltip>
        </p>
      </div>
      <vs-divider />
      <div class="ml-5">
        <router-link
          :to="{
            name: patientDetailRoute,
            params: { patientId: $route.params.patientId },
          }"
          >Patient details</router-link
        >
      </div>
    </vs-card>

    <vs-card>
      <div class="ml-5 mb-10">
        <div style="margin-top: 10px; margin-bottom: 25px">
          <h4 class="mt-2 mb-3">Treatment Plan</h4>
          <p v-if="modalData.treatments && modalData.treatments.length > 0">
            <strong style="text-decoration: underline">Treatment</strong>
            <strong style="margin-left: 5px">:</strong>
            {{ treatmentsNameArray.join(", ") }}
          </p>

          <div
            v-if="
              modalData.appointments &&
              modalData.appointments.treatmentPlans &&
              modalData.appointments.treatmentPlans.length > 0 &&
              modalData.appointments.treatmentPlans[0].product
            "
          >
            <p>
              <strong style="text-decoration: underline">Plans</strong>
            </p>
            <div
              v-for="plan in modalData.appointments.treatmentPlans"
              :key="plan._id"
              style
            >
              <p v-if="plan.product">
                {{ plan.product }}, {{ plan.area }}, {{ plan.amount }}
              </p>
            </div>
          </div>

          <div v-else>
            <p>
              <strong style="text-decoration: underline">Plans</strong>
              <strong style="margin-left: 5px">:</strong>
              N/A
            </p>
          </div>
        </div>

        <div style="margin-top: 10px; margin-bottom: 25px">
          <h5 class="mt-2">Pre - Treatment Photos</h5>
          <vs-row>
            <vs-col
              vs-w="3"
              v-for="photo in prePhotos"
              :key="photo.photoUrl"
              class="imageClass"
            >
              <a :href="photo.photoUrl" target="_blank">
                <img :src="photo.photoUrl" width="100%" />
              </a>
            </vs-col>
          </vs-row>
          <vs-button
            style="padding: 10px; margin-top: 10px"
            @click="showPrePhotoHistory"
            icon-pack="feather"
            icon="icon-eye"
            >View Pre Treatment Image Trial</vs-button
          >
          <p v-if="prePhotos.length === 0">N/A</p>
        </div>

        <div style="margin-top: 10px; margin-bottom: 25px">
          <h5 class="mt-2">Post - Treatment Photos</h5>
          <vs-row>
            <vs-col
              vs-w="3"
              v-for="photo in postPhotos"
              :key="photo.photoUrl"
              class="imageClass"
            >
              <a :href="photo.photoUrl" target="_blank">
                <img :src="photo.photoUrl" width="100%" />
              </a>
            </vs-col>
          </vs-row>
          <vs-button
            style="padding: 10px; margin-top: 10px"
            @click="showPostPhotoHistory"
            icon-pack="feather"
            icon="icon-eye"
            >View Post Treatment Image Trial</vs-button
          >
          <p v-if="postPhotos.length === 0">N/A</p>
        </div>

        <div style="margin-top: 10px; margin-bottom: 25px">
          <h5 class="mt-2">Procedure Notes</h5>
          <vs-row
            v-if="
              modalData.groupedProcedureNote &&
              modalData.groupedProcedureNote[0].partId
            "
          >
            <vs-col
              style="width: 350px; height: 600px"
              vs-w="3"
              v-for="photo in modalData.groupedProcedureNote"
              :key="photo.partImage"
              class="imageClass outer"
            >
              <img
                :src="photo.partImage"
                style="width: 100%; height: 100%"
                width="100%"
              />
              <div class="overlay" @click="overlayClicked(photo)">
                <p class="text">{{ photo.notes.length }} Notes</p>
              </div>
            </vs-col>
          </vs-row>
          <vs-row v-else>
            <p>N/A</p>
          </vs-row>
          <vs-button @click="noteDetail = true">Add Procedure Notes</vs-button>
        </div>
      </div>
    </vs-card>

    <vs-popup
      class="holamundo"
      :title="popupData.partName"
      :active.sync="popupActive"
    >
      <vs-row>
        <vs-col
          style="max-height: 500px"
          vs-type="flex"
          vs-justify="center"
          vs-align="center"
          vs-w="4"
        >
          <img :src="popupData.partImage" style="width: 100%; height: 100%" />
        </vs-col>
        <vs-col vs-type="flex" vs-align="left" vs-w="8">
          <ul class="noteUl">
            <li v-for="(note, index) in popupData.partNotes" :key="index">
              {{ note.note }}
            </li>
          </ul>
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-card>
      <div class="ml-5" v-if="modalData.appointments.doctorTreatmentOutcome">
        <h4 class="mb-3">Doctor Notes</h4>
        <p>Status: {{ modalData.appointments.doctorTreatmentOutcome.title }}</p>
        <p>Note: {{ modalData.appointments.doctorTreatmentOutcome.note }}</p>
        <vs-button
          style="padding: 10px; margin-top: 10px"
          @click="showDoctorNoteHistory"
          icon-pack="feather"
          icon="icon-eye"
          >View Audit Trail</vs-button
        >
      </div>
      <div class="ml-5" v-else>
        <h4 class="mb-3">Doctor Notes</h4>
        <p>N/A</p>
      </div>
    </vs-card>

    <vs-popup
      class="holamundo"
      title="Doctor Note History"
      :active.sync="treatmentOutcomeHistoryPopup"
    >
      <vs-row v-if="treatmentOutcomeHistory.length > 0">
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <ul
            style="list-style-type: circle; padding-left: 35px"
            class="w-full"
          >
            <li :key="index" v-for="(datum, index) of treatmentOutcomeHistory">
              <vs-card>
                <p>
                  {{ moment(datum.createdAt).format("DD/MM/YYYY") }}
                </p>
                <p>Status: {{ datum.title }}</p>
                <p>Note: {{ datum.note }}</p>
              </vs-card>
            </li>
          </ul>
        </vs-col>
      </vs-row>
      <vs-row v-else>
        <p>No history record found</p>
      </vs-row>
    </vs-popup>

    <vs-card>
      <div class="ml-5">
        <h4 class="mb-3">Consent Form</h4>
        <p>
          Signed At:
          {{ moment(modalData.consentFormSignedAt).format("DD/MM/YYYY") }}
        </p>
        <vs-button
          style="padding: 10px; margin-top: 10px"
          @click="showConsentForm"
          icon-pack="feather"
          icon="icon-eye"
          >View Consent Form</vs-button
        >
      </div>
    </vs-card>

    <vs-popup
      class="holamundo"
      title="Consent Form"
      :active.sync="consentPopup"
    >
      <vs-row
        v-if="
          modalData.treatmentConsentAgreed &&
          modalData.treatmentConsentAgreed.length > 0
        "
      >
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <p>Consent Agreed</p>
          <ul style="list-style-type: circle; padding-left: 35px">
            <li
              :key="index"
              v-for="(datum, index) of modalData.treatmentConsentAgreed"
            >
              {{ datum.question || "N/A" }}
            </li>
          </ul>
        </vs-col>

        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <div style="margin-top: 20px">
            <p>Signature</p>
            <div style="padding-left: 35px; width: 250px; height: auto">
              <img
                :src="modalData.eSignature"
                style="width: 100%; height: 100%"
              />
            </div>
          </div>
        </vs-col>
      </vs-row>
      <vs-row v-else>
        <p>No consent data found</p>
      </vs-row>
    </vs-popup>

    <!--pop up for post photo history-->
    <vs-popup
      class="holamundo"
      title="Post Treatment Photo History"
      :active.sync="postPhotosHistoryPopup"
    >
      <vs-row class="flex justify-end mb-2">
        <div>
          <label
            class="image-upload profile-image-upload"
            style="
              width: 255px;
              border: 1px solid #3dc9b3;
              display: inline-block;
              border-radius: 25px;
              padding: 5px;
            "
          >
            <div class="flex items-center justify-center">
              <vs-icon icon-pack="feather" icon="icon-upload" class="mr-1" />
              Upload Post - Treatment Photo
            </div>
            <input
              type="file"
              accept="image/*"
              @change="uploadImage($event, false, 'profile-image-upload')"
              id="file-input"
              hidden
            />
          </label>
        </div>
      </vs-row>
      <vs-row v-if="postPhotosHistory.length > 0">
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <ul
            style="list-style-type: circle; padding-left: 35px"
            class="w-full"
          >
            <li :key="index" v-for="(datum, index) of postPhotosHistory">
              <vs-card>
                <p>
                  {{ moment(datum.photos[0].createdAt).format("DD/MM/YYYY") }}
                </p>
                <vs-row>
                  <vs-col
                    vs-w="3"
                    v-for="photo in datum.photos"
                    :key="photo.photoUrl"
                    class="imageClass"
                  >
                    <a :href="photo.photoUrl" target="_blank">
                      <img :src="photo.photoUrl" width="100%" />
                    </a>
                    <vs-button
                      size="normal"
                      color="danger"
                      @click="deleteImage(photo)"
                      icon-pack="feather"
                      icon="icon-trash-2"
                      class="mr-2"
                    />
                  </vs-col>
                </vs-row>
              </vs-card>
            </li>
          </ul>
        </vs-col>
      </vs-row>
      <vs-row v-else>
        <p>No history record found</p>
      </vs-row>
    </vs-popup>

    <!--pop up for pre photo history-->
    <vs-popup
      class="holamundo"
      title="Pre Treatment Photo History"
      :active.sync="prePhotosHistoryPopup"
    >
      <vs-row class="flex justify-end mb-2">
        <div>
          <label
            class="image-upload profile-image-upload"
            style="
              width: 255px;
              border: 1px solid #3dc9b3;
              display: inline-block;
              border-radius: 25px;
              padding: 5px;
            "
          >
            <div class="flex items-center justify-center">
              <vs-icon icon-pack="feather" icon="icon-upload" class="mr-1" />
              Uplad Pre - Treatment Photo
            </div>
            <input
              type="file"
              accept="image/*"
              @change="uploadImage($event, true, 'profile-image-upload')"
              id="file-input"
              hidden
            />
          </label>
        </div>
      </vs-row>
      <vs-row v-if="prePhotosHistory.length > 0">
        <vs-col vs-type="flex" vs-align="left" vs-w="12">
          <ul
            style="list-style-type: circle; padding-left: 35px"
            class="w-full"
          >
            <li :key="index" v-for="(datum, index) of prePhotosHistory">
              <vs-card>
                <p>
                  {{ moment(datum.photos[0].createdAt).format("DD/MM/YYYY") }}
                </p>
                <vs-row>
                  <vs-col
                    vs-w="3"
                    v-for="photo in datum.photos"
                    :key="photo.photoUrl"
                    class="imageClass"
                  >
                    <a :href="photo.photoUrl" target="_blank">
                      <img :src="photo.photoUrl" width="100%" />
                    </a>
                    <vs-button
                      size="normal"
                      color="danger"
                      @click="deleteImage(photo)"
                      icon-pack="feather"
                      icon="icon-trash-2"
                      class="mr-2"
                    />
                  </vs-col>
                </vs-row>
              </vs-card>
            </li>
          </ul>
        </vs-col>
      </vs-row>
      <vs-row v-else>
        <p>No history record found</p>
      </vs-row>
    </vs-popup>

    <vs-popup title="Add Procedure Notes" :active.sync="noteDetail">
      <form autocomplete="off">
        <div class="flex flex-no-wrap m-5">
          <div class="mr-2 w-1/2">
            <vs-select
              id="gender"
              autocomplete
              :success="
                !errors.first('basic.bodyPartId') && newNote.bodyPartId != ''
              "
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.bodyPartId') ? true : false"
              v-validate="'required'"
              name="bodyPartId"
              data-vv-as="bodyPartId"
              label="Gender"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.bodyPartId')"
              v-model="newNote.bodyPartId"
            >
              <vs-select-item
                :key="index"
                :value="item._id"
                :text="item.gender"
                v-for="(item, index) in bodyParts"
              />
            </vs-select>
          </div>
          <div class="ml-2 w-1/2">
            <vs-select
              id="parts"
              autocomplete
              :success="!errors.first('basic.partId') && newNote.partId != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.partId') ? true : false"
              v-validate="'required'"
              name="partId"
              data-vv-as="partId"
              label="Body part"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.partId')"
              v-model="newNote.partId"
            >
              <vs-select-item
                :key="index"
                :value="part._id"
                :text="part.name"
                v-for="(part, index) in parts"
              />
            </vs-select>
          </div>
        </div>
        <div class="w-full">
          <div class="m-5">
            <vs-input
              :success="!errors.first('basic.title') && newNote.title != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.title') ? true : false"
              v-validate="'required|max:150'"
              name="title"
              data-vv-as="title"
              label="Title"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.title')"
              v-model="newNote.title"
            />
          </div>

          <div class="m-5">
            <vs-textarea
              :success="!errors.first('basic.note') && newNote.note != ''"
              val-icon-success="done"
              val-icon-danger="error"
              :danger="errors.first('basic.note') ? true : false"
              v-validate="'required|max:1000'"
              name="note"
              data-vv-as="note"
              label="Note"
              class="w-full"
              data-vv-scope="basic"
              :danger-text="errors.first('basic.note')"
              v-model="newNote.note"
            />
          </div>
          <div class="m-5">
            <vs-button
              size="normal"
              @click="saveData"
              icon-pack="feather"
              class="mr-2"
              >Save Procedure Notes</vs-button
            >
          </div>
        </div>
      </form>
    </vs-popup>
  </div>
</template>

<script>
import vSelect from "vue-select";
import { mapActions } from "vuex";
import moment from "moment";
import _ from "lodash";

export default {
  components: {
    "v-select": vSelect,
  },
  props: {
    backRoute: {
      type: String,
      // default: 'FranchisePatientDetail'
    },
    patientDetailRoute: {
      type: String,
      // default: 'FranchisePatientDetail'
    },
  },
  data() {
    return {
      newNote: {
        title: "",
        note: "",
        partId: "",
        bodyPartId: "",
      },
      bodyParts: [],
      parts: [],
      modalData: {
        createdAt: "",
        nurse: {},
        doctor: {},
        patient: {},
        appointments: {},
      },
      noteDetail: false,
      treatmentsNameArray: [],
      showScripted: true,
      isScripted: false,
      prePhotos: [],
      postPhotos: [],
      popupActive: false,
      popupData: {
        partName: "",
        partImage: "",
        partNotes: "",
      },
      consentPopup: false,
      treatmentOutcomeHistoryPopup: false,
      treatmentOutcomeHistory: [],
      postPhotosHistoryPopup: false,
      prePhotosHistoryPopup: false,
      postPhotosHistory: [],
      prePhotosHistory: [],
    };
  },
  watch: {
    "newNote.bodyPartId"(val) {
      this.parts = _.filter(this.bodyParts, ["_id", val])[0].parts;
    },
  },
  computed: {
    isDocEMRTreatment() {
      return `${this.modalData.nurse._id}` === `${this.modalData.doctor._id}`;
    }
  },
  methods: {
    ...mapActions("nurse", [
      "getTreatmentDetails",
      "updateNotes",
      "getBodyParts",
      "uploadAppointmentPhoto",
      "deleteAppointmentPhoto",
    ]),
    ...mapActions("general", ["showScriptPdf"]),
    ...mapActions("doctor", [
      "fetchTreatmentOutcomesList",
      "fetchPostPhotoHistoryList",
    ]),
    async saveData() {
      try {
        let isValid = await this.$validator.validateAll("basic");
        if (isValid) {
          await this.updateNotes({
            appointmentId: this.modalData.appointments._id,
            update: this.newNote,
          });
          this.treatmentDetails();
          this.newNote = {
            title: "",
            note: "",
            partId: "",
            bodyPartId: "",
          };
          this.$validator.reset();
          this.noteDetail = false;
        }
      } catch (error) {
        console.error(error);
      }
    },
    async uploadImage(event, isBeforePhoto, loadingClass = "") {
      try {
        this.$vs.loading();
        await this.uploadAppointmentPhoto({
          event: event,
          isBeforePhoto: isBeforePhoto,
          appointmentId: this.modalData.appointments._id,
        });
        this.postPhotosHistoryPopup = false;
        this.prePhotosHistoryPopup = false;
        this.mount();
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
        if (error && error.response.status === 415) {
          this.$vs.notify({
            title: "Upload Failed",
            text: "File Format Not Supported",
            color: "danger",
          });
        } else {
          this.$vs.notify({
            title: "Upload Failed",
            text: "Please try again later.",
            color: "danger",
          });
        }
      }
    },
    async deleteImage({ photoUrl, _id , isBeforePhoto}) {
      try {
        this.$vs.loading();
        const photoId = isBeforePhoto?_.filter(this.prePhotos, ["photoUrl", photoUrl])[0]._id:_.filter(this.postPhotos, ["photoUrl", photoUrl])[0]._id
        await this.deleteAppointmentPhoto({
          appointmentHistoryId: _id,
          photoId: photoId,
          appointmentId: this.modalData.appointments._id,
          imageUrl: photoUrl,
        });
        this.postPhotosHistoryPopup = false;
        this.prePhotosHistoryPopup = false;
        this.mount();
        this.$vs.loading.close();
      } catch (error) {
        this.$vs.loading.close();
        console.log(error);
      }
    },
    async treatmentDetails(checkInId = null) {
      let patientCheckinId = this.$route.params.checkinId;

      if (checkInId) {
        patientCheckinId = checkInId;
      }

      await this.getTreatmentDetails(patientCheckinId).then((res) => {
        this.modalData = res.data.data;
        if (this.modalData.treatments && this.modalData.treatments.length > 0)
          this.treatmentsNameArray = Object.keys(this.modalData.treatments).map(
            (index) => {
              return this.modalData.treatments[index].treatmentName;
            }
          );
      });
    },
    async getParentData(parentAppointmentCheckInId) {
      this.$vs.loading();
      await this.treatmentDetails(parentAppointmentCheckInId);
      this.postPhotos = [];
      this.prePhotos = [];
      if (this.modalData.appointments.photos) {
        this.modalData.appointments.photos.forEach((photo) => {
          if (photo.isBeforePhoto == false) {
            this.postPhotos.push(photo);
          } else this.prePhotos.push(photo);
        });
      }
      this.showScripted = false;
      this.$vs.loading.close();
    },
    async getChildData() {
      this.$vs.loading();
      await this.treatmentDetails();
      this.postPhotos = [];
      this.prePhotos = [];
      if (this.modalData.appointments.photos) {
        this.modalData.appointments.photos.forEach((photo) => {
          if (photo.isBeforePhoto == false) {
            this.postPhotos.push(photo);
          } else this.prePhotos.push(photo);
        });
      }
      this.showScripted = true;
      this.$vs.loading.close();
    },
    moment(date) {
      return moment(date);
    },
    createNurseHandler() {
      this.$router.push({ name: "FranchiseNurseCreate" });
    },
    overlayClicked(params) {
      this.popupActive = true;
      this.popupData = {
        partImage: params.partImage,
        partName: params.partName,
        partNotes: params.notes,
      };
    },
    showConsentForm() {
      this.consentPopup = true;
    },
    showScriptPdfClicked() {
      this.$vs.loading();
      this.showScriptPdf({ appointmentId: this.modalData.appointments._id })
        .then((result) => {
          const link = document.createElement("a");
          link.href = result.data.data.pdfLink;
          link.setAttribute("target", "_blank");
          link.click();
          this.$vs.loading.close();
        })
        .catch((err) => {
          this.$vs.loading.close();
        });
    },
    showDoctorNoteHistory() {
      this.treatmentOutcomeHistoryPopup = true;
    },
    getDoctorNoteHistory(appointmentId) {
      this.fetchTreatmentOutcomesList(appointmentId).then((res) => {
        this.treatmentOutcomeHistory = res.data.data;
      });
    },
    showPrePhotoHistory() {
      this.prePhotosHistoryPopup = true;
    },
    showPostPhotoHistory() {
      this.postPhotosHistoryPopup = true;
    },
    async getParts() {
      const res = await this.getBodyParts();
      this.bodyParts = res.data.data;
    },
    getPostPhotoHistory(appointmentId) {
      const data = {
        id: appointmentId,
        isBeforePhoto: false,
      };
      this.fetchPostPhotoHistoryList(data).then((res) => {
        this.postPhotosHistory = res.data.data;
      });
    },
    getPrePhotoHistory(appointmentId) {
      const data = {
        id: appointmentId,
        isBeforePhoto: true,
      };
      this.fetchPostPhotoHistoryList(data).then((res) => {
        this.prePhotosHistory = res.data.data;
      });
    },
    async mount() {
      await this.getParts();
      await this.treatmentDetails();
      this.postPhotos = [];
      this.prePhotos = [];
      if (this.modalData.appointments.photos) {
        this.modalData.appointments.photos.forEach((photo) => {
          if (photo.isBeforePhoto == false) {
            this.postPhotos.push(photo);
          } else this.prePhotos.push(photo);
        });
      }

      if (this.modalData.appointments.isScripted) {
        this.isScripted = true;
      }

      if (this.modalData.appointments.doctorTreatmentOutcome) {
        await this.getDoctorNoteHistory(this.modalData.appointments._id);
      }

      if (this.modalData.appointments.photos) {
        await this.getPostPhotoHistory(this.modalData.appointments._id);
        await this.getPrePhotoHistory(this.modalData.appointments._id);
      }
    },
  },
  async mounted() {
    this.mount();
  },
};
</script>
<style>
.imageClass {
  padding: 5px;
  margin: 5px;
}
.imageClass img {
  padding: 5px;
}

p {
  font-size: 15px;
  font-weight: 500;
}

.outer {
  position: relative;
}

.overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  background: black;
  opacity: 0.5;
  top: 0;
  left: 0;
  cursor: pointer;
}
.text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  cursor: pointer;
}

ul.noteUl {
  padding-left: 50px;
  list-style: disc;
  text-align: justify;
}

ul.noteUl li {
  margin: 10px 0;
}
</style>
